import React from 'react';

const SvgComponent = ({ width = '675', height = '675' }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={`${width}px`} // Using the passed width in pixels
    height={`${height}px`} // Using the passed height in pixels
    viewBox="0 0 224.000000 221.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,221.000000) scale(0.100000,-0.100000)" fill="#FFF" stroke="none">
      <path d="M1010 2111 c0 -57 -4 -83 -12 -85 -7 -2 -53 -13 -103 -25 -95 -22 -225 -79 -301 -131 -173 -120 -308 -308 -370 -517 -39 -130 -45 -316 -14 -450 26 -116 97 -271 163 -359 52 -69 158 -171 218 -211 l39 -25 105 63 c58 35 105 67 105 71 0 4 -10 10 -22 14 -44 13 -154 92 -210 150 -166 173 -233 410 -183 649 33 160 157 341 295 433 68 46 189 98 248 108 l41 6 3 -75 3 -76 212 132 c117 73 212 135 213 139 0 7 -387 254 -415 264 -13 5 -15 -7 -15 -75z"/>
      <path d="M1503 1827 c-57 -34 -103 -65 -103 -69 0 -4 10 -10 23 -14 81 -25 224 -150 291 -254 82 -126 116 -240 116 -391 0 -289 -172 -545 -443 -657 -40 -17 -92 -34 -115 -38 l-41 -6 -3 75 -3 76 -212 -132 c-117 -73 -212 -135 -213 -139 0 -7 387 -254 415 -264 13 -5 15 7 15 75 0 57 4 83 13 85 6 2 53 13 102 25 95 22 225 79 301 131 121 84 238 219 305 352 64 128 90 233 96 385 7 163 -16 279 -82 420 -52 111 -88 164 -167 249 -55 58 -171 154 -186 154 -4 0 -53 -28 -109 -63z"/>
      <path d="M1058 1523 c-3 -55 -3 -56 -42 -70 -124 -42 -183 -171 -128 -279 25 -50 72 -83 183 -129 100 -42 129 -65 129 -104 0 -69 -109 -92 -237 -49 l-72 24 -16 -64 c-8 -35 -13 -67 -11 -71 6 -9 98 -35 159 -46 l37 -6 0 -54 0 -55 55 0 55 0 0 60 c0 57 1 60 28 66 107 26 182 114 182 214 0 104 -51 159 -209 224 -82 34 -131 70 -131 98 0 5 11 20 25 33 20 21 34 25 83 25 32 0 81 -7 108 -15 27 -8 52 -15 55 -15 4 0 12 21 18 48 7 26 14 54 17 63 3 13 -7 21 -42 33 -26 9 -58 16 -70 16 -45 0 -54 10 -54 61 l0 49 -60 0 -59 0 -3 -57z"/>
    </g>
  </svg>
);

export default SvgComponent;
