import React from "react";
import "./Hero.css";
import { Fade } from "react-reveal";
import RateCalculator from "../../components/rateCalculator/RateCalculator.js";
import { FRONTEND_URL } from "../../util/urls";

export default function Hero(props) {
  return (
    <div className="hero-main">
      <div className="hero-main-container">
        <div className="hero-main-title-calculator-container">
          <div
            style={{ disply: "flex", flex: 0.4, color: "#000", lineHeight: 1 }}
          >
            <span className="hero-main-title">
              Kick-start your holiday shipping with Shippy
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: "1.4rem",
                    lineHeight: 1.4,
                    color: "#6e6e6e",
                  }}
                >
                  Get discounted shipping rates with{" "}
                  <span style={{ color: "#4d148c", fontWeight: "bold" }}>
                    Fed
                  </span>
                  <span style={{ color: "#ff6600", fontWeight: "bold" }}>
                    Ex
                  </span>
                  ,{" "}
                  <span style={{ color: "#2a52be", fontWeight: "bold" }}>
                    USPS
                  </span>{" "}
                  <br />
                  and{" "}
                  <span style={{ color: "#351c15", fontWeight: "bold" }}>
                    UPS
                  </span>
                  <span>, track orders and print shipping labels</span>
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingRight: "3rem",
                marginTop: "1rem",
              }}
            >
              {FRONTEND_URL && (
                <a
                  href={`${FRONTEND_URL}/sign-up`}
                  style={{
                    display: "flex",
                    padding: "1rem",
                    width: "13rem",
                    justifyContent: "center",
                    backgroundColor: "#6738ee",
                    color: "#FFF",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <span>Sign up now</span>
                </a>
              )}
            </div>
          </div>

          <div className="hero-main-rate-calulator-container">
            <RateCalculator />
          </div>
        </div>
        <Fade top duration={500}></Fade>
      </div>
    </div>
  );
}
