import React, { useState } from "react";
import TopButton from "../../components/topButton/TopButton";
import "./ContactComponent.css";
import { BACKEND_API_URL } from "../../util/urls"

const Contact = (props) => {
  const [email, setEmail] = useState('');
  const [contacted, setContacted] = useState(false);

  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const handleContactMe = () => {
    const requestBody = JSON.stringify({
      "email": email,
      "message": ""
    })
  
    const requestOptions = {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json",
      },
      body: requestBody
    }

    fetch( `${BACKEND_API_URL}/api/email`, requestOptions)
      .then((res) => {
        setContacted(true);
      })
      .catch(error => {
        console.log('error', error)
      });
  }
  
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '90%', margin: '0 auto', paddingTop: '6rem', paddingBottom: '6rem' }}>
        <div style={{ display: 'flex', alignItems: 'center',  width: '100%', margin: '0 auto', flexDirection: 'column'}}>
          <span style={{ fontSize: '3rem', textAlign: 'center', color: '#000' }}>Let's simplify your shipping</span>
          <span style={{ fontSize: '1.3rem', color: '#000', marginTop: '1.5rem' }}/>
          <div className="contact-component-input-email-container">
            {!contacted && (
              <>
                <input style={{ flex: 0.8, fontSize: '1.4rem', paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }} type="text" placeholder="Your email address" onChange={handleEmailChange}></input>
                <div className="contact-component-input-email-container-input" onClick={handleContactMe}>
                    <span style={{ fontSize: '1.3rem'}}>Contact me</span>
                  </div>
              </>
            )}
          </div>
          {contacted && (
            <span style={{ fontSize: '1.3rem', color: '#000', marginTop: '1.5rem' }}>Thanks for reaching out to Shippy, we'll be in touch!</span>
          )}
        </div>
      </div>
      <TopButton theme={props.theme} />
    </>
  );
}

export default Contact;
