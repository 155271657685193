import React from "react";
import "./Image.css";

const Image = ({ src, alt = "", style = {} }) => {
  // Assuming src can be a local path or a direct URL
  const imageUrl = src.includes('http://') || src.includes('https://') ? src : require.context("../../assets/images", false, /\.(webp|png|jpe?g)$/)(src);

  return <img className="image" style={style} src={imageUrl} alt={alt} />;
};

export default Image;
