import React, { Component } from "react";
import "./WhyShippyProject.css";
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";
import ParcelBoxSvg from "../../components/svg/ParcelBoxSvg.js"
import PrinterSvg from "../../components/svg/PrinterSvg.js"
import TransitRateSvg from "../../components/svg/TransitRateSvg.js"

class WhyShippyProject extends Component {
  render() {
    const project = this.props.project;

    const workTextClassName =
      project.imageAlign === "left"
        ? "whyshippy-project-text-div-left"
        : "whyshippy-project-text-div-right";

    return (
      <div className="whyshippy-project-main-div">
        {project.imageAlign === "left" && (
          <div
            style={{ flex: 0.55}}
          className="work-image-div">
            <Image src={"https://shippy-public-images.s3.eu-west-1.amazonaws.com/compare_rates.avif"} />
          </div>
        )}
        <div
          style={{ flex: 0.45 }}
        className={workTextClassName}>
          <Fade>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <div style={{ marginRight: '0.5rem'}}>
                    <ParcelBoxSvg height="40" width="40"/>
                  </div>
                  <span className="whyshippy-project-title">
                    {this.props.title}
                  </span>
                </div>
                <div 
                  style={{ marginTop: '0.5rem'}}
                  className="whyshippy-project-description">
                  {this.props.descriptionOne}
                </div>
              </div>
              <div
                style={{ marginTop: '2rem'}}>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <div style={{ marginRight: '0.5rem'}}>
                    <PrinterSvg height="40" width="40"/>
                  </div>
                  <span className="whyshippy-project-title">
                    {this.props.titleTwo}
                  </span>
                </div>
                <div 
                  style={{ marginTop: '0.5rem'}}
                  className="whyshippy-project-description">
                  {this.props.descriptionTwo}
                </div>
              </div>

              <div
                style={{ marginTop: '2rem'}}>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <div style={{ alignItems: 'center', alignSelf: 'center', marginRight: '0.5rem'}}>
                    <TransitRateSvg height="40" width="40"/>
                  </div>
                  <span className="whyshippy-project-title">
                    {this.props.titleThree}
                  </span>
                </div>
                <div 
                  style={{ marginTop: '0.5rem'}}
                  className="whyshippy-project-description">
                  {this.props.descriptionThree}
                </div>
              </div>
          </Fade>
        </div>
        {project.imageAlign === "right" && (
          <div className="work-image-div">
            <Image src={"https://shippy-public-images.s3.eu-west-1.amazonaws.com/compare_rates.avif"} />
          </div>
        )}
      </div>
    );
  }
}

export default WhyShippyProject;
