import React from 'react';

const SvgComponent = ({ width = '67.5', height = '67.5' }) => (
  <svg 
    width={`${width}px`} 
    height={`${height}px`} 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="#6738ee"/>
    <path d="M18.75 17H20C20.5523 17 21 16.5523 21 16V8C21 7.44772 20.5523 7 20 7H4C3.44772 7 3 7.44772 3 8V16C3 16.5523 3.44772 17 4 17H5.25" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12V20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20V12Z" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 4C6 3.44772 6.44772 3 7 3H17C17.5523 3 18 3.44772 18 4V7H6V4Z" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.5 13.5H15.5" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.5 18.5H15.5" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.5 16H15.5" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SvgComponent;
