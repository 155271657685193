import React, { Component } from "react";
import "./WhyShippySection.css";
import WhyShippyProject from "./WhyShippyProject";
import { work } from "../../portfolio.js";

class WhyShippySection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div
        className="whyshippy-section-container"
        style={{ 
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#6738ee',
          color: this.props.textColor, fontFamily: this.props.font }}
      >            
        <div style={{ paddingTop: '5rem', paddingBottom: '1rem'}}className="whyshippy-section-title">
          Why use Shippy?
        </div>
        <div style={{ paddingTop: '3rem', paddingBottom: '10rem', margin: '0 auto', maxWidth: '95%'}}>
          {work.data.map((project) => {
            return (
              <>
                {project.imageAlign === "left" && (
                  <WhyShippyProject theme={theme} key={project.id} 
                    title={"Ship with multiple carriers in one convenient platform"} 
                    descriptionOne={"Shippy lets you compare rates between multiple carriers, pick the one that works best for you and track your orders in a single place."}
                    
                    titleTwo={"Easy access to all your shipping labels"} 
                    descriptionTwo={"After purchasing a shipment you'll get a printable shipping label for the package. You just need to print it, stick it on the package and hand it over to the carrier."}
                    
                    titleThree={"Deeply discounted shipping rates"} 
                    descriptionThree={"We negotiate discounts for you so you can focus on providing excellent shopping experiences for your customers"}
                    
                    project={project} />
                )}
                {/* {project.imageAlign === "right" && (
                  <WhyShippyProject theme={theme} key={project.id} project={project} />
                )} */}
              </>
            );
          })}
        </div>
      </div>
    );
  }
}

export default WhyShippySection;
